<template>
	<div class="top">
		<div
			class="logo-position"
			@click="toMain"
			:style="bg_logo"
		>
			<span
				v-if="!program.shop_logo"
			>{{ title }}</span>
		</div>
	</div>
</template>

<script>
export default{
	name: 'Top'
	, props: ['user', 'seller_info', 'program']
	, computed: {
		title: function(){
			let t = process.env.VUE_APP_NAME
			if(this.program.name){
				t = this.program.name
			}
			return t
		}
		, bg_logo: function(){
			let t = ''
			if(this.program.shop_logo){
				t = 'background-image: url(' + this.program.shop_logo + ');'
			}
			return t
		}
	}
	, methods: {
		onSide: function(){
			this.$emit('toggleSide')
		}
		, toMain: function(){
			this.$bus.$emit('to', {name: 'Index', not_query: false})
		}
	}
	, created() {

	}
}
</script>

<style>
.top {
	background-color: var(--bg-primary);
	color: var(--color-eee);
	font-size: var(--size-px-16);
	font-weight: var(--weight-600);
}
.shop-logo { max-width: 300px; max-height: 100px }
</style>