import axios from 'axios'

let domain = process.env.VUE_APP_DOMAIN
let dev = process.env.VUE_APP_DEV
let server = process.env.VUE_APP_SERVER
let location = window.location.hostname

let baseUrl = ''

if(location == domain){
	baseUrl = server
}else{
	baseUrl = dev
}

export async function Axios({ method, url, data, header, authorize, multipart = true, blob }){

	const instance = axios.create({
		baseURL: baseUrl
		,timeout: 30000
	})

	const getUrl = function(){
		return url
	}

	const getParams = function(){
		if(method == 'get'){
			if(!data){
				data = {

				}
			}
			return data
		}
	}

	const getData = function(){

		let formData = ''
		if(multipart){
			formData = new FormData();
			for(let key in data){
				if(Array.isArray(data[key])){

					for(let i = 0; i < data[key].length; i++) {

						if(Array.isArray(data[key][i])) {
							for (let sub_key in data[key][i]) {
								formData.append(`${key}[` + i + '].' + sub_key, transVal(data[key][i][sub_key]))
							}
						}else{
							formData.append(`${key}`, transVal(data[key][i]))
						}
					}
				}else {
					formData.append(key, transVal(data[key]))
				}
			}

			return formData
		}else{
			// return { d: data }
			return formData
		}

	}


	const transVal = function(value){
		if( value === "" || value === null || value === undefined){
			return ''
		}else{
			return value
		}
	}


	const getHeader = function(){
		
		let default_header = {
			// 'Content-Type': 'application/json;'
		}
		
		if(authorize){
			default_header.Authorization = 'Bearer ' + (TOKEN ? TOKEN : sessionStorage.getItem(process.env.VUE_APP_NAME + 'T'))
		}
		let TOKEN = sessionStorage.getItem(process.env.VUE_APP_NAME + 'T3')
		if(TOKEN){
			default_header.P = TOKEN
		}

		if(multipart){
			default_header['Content-Type'] += 'application/json'
		}
		
		if(blob){
			default_header['responseType'] = 'blob'
		}
// console.log('header', header, default_header)
		return header ? header : default_header
	}
	
	try{
		const result = await instance({
			method: method
			,url: getUrl()
			,params: getParams()
			,data: getData()
			,headers: getHeader()
		})
		
		if(result.status == 200){
			if(!result.data.result){
				// this.$bus.$emit('notify', { type: 'error', message: 'false'})
			}

			if(result?.data?.data?.code == 401){

				/*
				localStorage.removeItem(process.env.VUE_APP_NAME)
				localStorage.removeItem(process.env.VUE_APP_NAME)
				localStorage.removeItem(process.env.VUE_APP_NAME)
				sessionStorage.clear()
*/
				this.$bus.$emit('onModal', {
					top: true
					, close: true
					, title: '오류'
					, content: result.data.message
					, to: 'Login'
				})
			}
			return result.data
		}else{
			const data = result.data
			// console.log(data.status)
			if(data.status == 40120){
				return {success: false, message: '인증이 만료되었습니다'}
			}else{
				return {success: false, message: '통신오류: ' + result.status}
			}
		}
	}catch(e){
		if(JSON.stringify(e).indexOf('Request failed with status code 401') > -1){

			localStorage.removeItem(process.env.VUE_APP_NAME)
			localStorage.removeItem(process.env.VUE_APP_NAME)
			localStorage.removeItem(process.env.VUE_APP_NAME)
			sessionStorage.clear()

			this.$bus.$emit('onModal', {
				top: true
				, close: true
				, title: '오류'
				, content: '통신오류'
				, to: 'Login'
			})
		}
		// console.log('Axios result error')
		return {success: false, message: '통신오류'}
	}
}



