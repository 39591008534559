<template>
	<div
		class="bottom justify-space-between text-center box-shadow-top position-relative bg-base position-relative"
	>
		<span
			class="flex-1 ptb-10 flex-column"
			@click="onSide"
		>
			<v-icon
				class="color-base"
			>mdi mdi-menu</v-icon>
		</span>

		<span
			class="flex-1 ptb-10 flex-column"
			:class="{on: is_home}"
			@click="toHome"
		>
			<v-icon
				class="color-base"
			>mdi mdi-home-outline</v-icon>
		</span>

		<span
			class="flex-1 ptb-10 flex-column"
			@click="toCalculate"
			:class="{on: is_calculate}"
		>
			<v-icon
				class="color-base"
			>mdi mdi-calculator</v-icon>
		</span>
		<span
			class="flex-1 ptb-10 flex-column position-relative"
			@click="toWallet"
			:class="{on: is_wallet}"
		>
			<v-icon
				class="color-base"
			>mdi mdi-wallet</v-icon>
		</span>
	</div>
</template>

<script>
	export default{
		name: 'Bottom'
		,props: ['cart_cnt']
		,data: function(){
			return {

			}
		}
		,computed: {
			is_home: function(){
				if(this.$route.path == '/Index'){
					return true
				}else{
					return false
				}
			}
			, is_wallet: function(){
				if(this.$route.path.indexOf('/Wallet') > -1){
					return true
				}else{
					return false
				}
			}
			,is_calculate: function(){
				if(this.$route.path.indexOf('/Calculate') > -1){
					return true
				}else{
					return false
				}
			}
		}
		,methods: {
			toHome: function(){
				this.$bus.$emit('to', { name: 'Index'})
			}
			, toCalculate: function(){
				this.$bus.$emit('to', { name: 'CalculateList'})
			}
			, toWallet: function(){
				this.$bus.$emit('to', { name: 'WalletList'})
			}
			, onSide: function(){
				this.$emit('toggleSide')
			}
		}
	}
</script>

<style>

	.bottom .on { background-color: #eee; color: #fefefe;}
	.bottom .on i { color: #333 !important;}
</style>