<template>
	<div
		class="position-fixed-full z-index-layer"
	>
		<div class="bg-layer z-index-bg"></div>
		<div
			v-if="is_full"
			class="position-relative flex-column justify-center full-width full-height z-index-contents"
			style="max-width: 480px; margin: 0 auto"
		>
			<div
				v-if="is_top"
				class="bg-popup-title pa-10-20 flex-row justify-space-between items-center under-line radius-top-20"
			>
				<h4><slot name="title"></slot></h4>
				<v-icon
					v-if="!not_cancel"
					@click="$emit('cancel')"
				>mdi-close-circle</v-icon>
			</div>
			<slot name="body"></slot>

			<div
				v-if="is_bottom"
				class="bg-none top-line flex-row justify-space-between radius-bottom-20"
			>
				<button
					v-if="!not_cancel"
					@click="$emit('cancel')"
					class="btn btn-dark-gray"
				><slot name="name-cancel">취소</slot></button>
				<button
					@click="$emit('click')"
					class="btn btn-primary"
				><slot name="name-confirm">확인</slot></button>
			</div>
		</div>
		<div
			v-else
			class="position-relative pa-10 flex-column justify-center full-width full-height z-index-contents"
			style="max-width: 480px; margin: 0 auto"
		>
			<div
				v-if="is_top"
				class="bg-popup-title pa-10-20 flex-row justify-space-between items-center under-line radius-top-20"
			>
				<h4><slot name="title"></slot></h4>
				<v-icon
					v-if="!not_cancel"
					@click="$emit('cancel')"
				>mdi-close-circle</v-icon>
			</div>
			<slot name="body"></slot>

			<div
				v-if="is_bottom"
				class="bg-none top-line flex-row justify-space-between radius-bottom-20"
			>
				<button
					v-if="!not_cancel"
					@click="$emit('cancel')"
					class="btn btn-dark-gray"
				><slot name="name-cancel">취소</slot></button>
				<button
					@click="$emit('click')"
					class="btn btn-primary"
				><slot name="name-confirm">확인</slot></button>
			</div>
		</div>

	</div>
</template>

<script>
export default {
	name: 'PopupLayer'
	, props: ['is_full', 'is_top', 'is_bottom', 'not_cancel']
}
</script>

<style>
	.position-fixed-full {
		position: fixed; left: 0; top: 0; width: 100%; height: 100%;
	}
	.z-index-layer {
		z-index: 9999;
	}
	.z-index-contents {
		z-index: 9998;
	}
	.z-index-bg {
		z-index: 9997;
	}

	.bg-layer {
		position: absolute; left: 0; top: 0; width: 100%; height: 100%; opacity: 0.5; background-color: black;
	}

	.bg-popup-title { background-color: var(--bg-white); color: #333;}
	.font-popup-main { font-size: 16px; font-weight: 700}
	.font-popup-sub { font-size: 14px; font-weight: 500}


</style>