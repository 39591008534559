<template>
	<div
		class="full-height"
		style="overflow: hidden; background-color: #f8f8f8"
	>
		<Loading
			v-if="is_loading"
		></Loading>
		<div
			v-if="is_ready"
			class="full-height"
			style=" max-width: 480px; margin: 0 auto"
		>
		<Side
			v-if="is_side"

			:user="user"
			:seller_info="seller_info"
			:category="category"

			@toggleSide="toggleSide"
			@push="toLocation"
		>
		</Side>
		<div
			class="full-height flex-column"
		>
			<Top
				v-if="program.top"
				:program="program"
				:user="user"
				:seller_info="seller_info"

				@push="toLocation"
			></Top>
			<Title
				v-if="program.title"
				:program="program"
				:cart_cnt="cart_cnt"

				@push="toLocation"
			></Title>

			<router-view
				:user="user"
				:seller_info="seller_info"
				:key="$route.fullPath"
				:codes="codes"

				@setNotify="setNotify"
				@onLoad="setProgram"

				@push="toLocation"
				@toBack="toBack"
				@onLoading="onLoading"
				@offLoading="offLoading"
				class=" overflow-y-auto"
			></router-view>

			<Bottom
				v-if="program.bottom"

				:cart_cnt="cart_cnt"
				@push="toLocation"
				@toggleSide="toggleSide"
			></Bottom>
		</div>
		</div>

		<Notify
			:msg="notifyCondition.message"
			:type="notifyCondition.type"

			@clearMsg="clearMsg"
		/>

		<Modal
			:is_modal="is_modal"
			@close="is_modal = false"
		></Modal>

		<SmsConfirm
			v-if="is_on_sms_confirm"
			:user="user"
			:type="sms_confirm_type"

			@click="smsCallback"
			@cancel="offSmsConfirm"
		></SmsConfirm>

		<Pin
			:user="user"
		></Pin>

		<popup-confirm
			v-if="is_on_notice"
			:not_cancel="true"
			@click="toMypage"
		>
			<template
				v-slot:title
			>보안 설정 안내</template>
			<template
				v-slot:content
			>SMS 인증 및 PIN 설정 후 이용하세요</template>
		</popup-confirm>

		<popup-layer
			v-if="is_on_notice_popup"
		>
			<template
				slot="body"
			>
				<div>
					<img src="/images/notice.jpg" class="width-100"/>
					<div class="bg-white pa-10 text-right">
					<button
						@click="is_on_notice_popup = false"
					>닫기</button>
					</div>
				</div>
			</template>
		</popup-layer>
	</div>
</template>

<script>

	import Notify from '@/components/AlertMsg'
	import Side from "@/view/Layout/Side";
	import Top from "@/view/Layout/Top";
	import Bottom from "@/view/Layout/Bottom";
	import Title from "@/view/Layout/Title";
	import Loading from "@/view/Layout/Loading";
	import Modal from "../../components/Modal";
	import SmsConfirm from "./SmsConfirm";
	import Pin from "../Auth/Pin";
	import PopupConfirm from "./PopupConfirm";
	import PopupLayer from "./PopupLayer";
	
	export default{
		name: 'Layout'
		,props: ['user', 'codes', 'seller_info']
		,components: {PopupLayer, PopupConfirm, Pin, SmsConfirm, Modal, Loading, Title, Bottom, Side, Top, Notify }
		,data: function(){
			return {
				program: {
				}
				,notifyCondition: {
					message: '메시지 입니다'
					,type: ''
				}
				,is_side: false
				,cart_items: [

				]
				,is_loading: true
				,is_ready: false
				,category: null
				, is_modal: false
				, is_on_sms_confirm: false
				, sms_confirm_type: ''
				, is_on_notice: false
				, is_on_notice_popup: false
			}
		}
		,computed:{
			cart_cnt: function(){
				return this.cart_items.length
			}
		}
		,methods: {

			setNotify: function({type, message}){
				this.notifyCondition = {
					message: message
					,type: type
				}
			}
			,clearMsg: function(){
				this.notifyCondition.message = ''
			}
			,toggleSide: function(){
				this.is_side = !this.is_side
			}
			,setProgram: function(program){
				this.program = program
			}

			,toBack: function(){
				this.$router.back()
			}
			,toLocation: function( {name, params, query}){
				this.$router.push({ name: name, params: params, query: query}).catch(function(e){
					console.log(e)
				});
			}
			,onLoading: function(){
				this.is_loading = true
			}
			,offLoading: function(){
				setTimeout(() => {
					this.is_loading = false
				}, 300)
			}
			, onSmsConfirm: function(type){
				this.is_on_sms_confirm = true

				this.sms_confirm_type = type

			}
			, offSmsConfirm: function(){
				this.is_on_sms_confirm = false
				this.sms_confirm_type = ''
			}
			, smsCallback: function (item){
				this.$bus.$emit('smsCallback', item)
				this.$bus.$emit('updateUser', {
					is_sms: true
				})
				sessionStorage.setItem('is_sms', true)
				this.offSmsConfirm()
			}
			, toMypage: function(){
				this.is_on_notice = false
				this.$bus.$emit('to', { name: 'MyPage'})
			}

		}
		,created: function(){

			this.$bus.$on('on', (type) =>{
				if(type){
					this.onLoading()
				}else{
					this.offLoading()
				}
			})

			this.$bus.$on('onLoad', (program) => {
				this.program = program
			})

			this.$bus.$on('onSmsConfirm', (type) => {
				this.onSmsConfirm(type)
			})


		}
		,watch: {
			'codes.P002': {
				immediate: true
				,deep: true
				,handler: function(call){
					let skip = false
					let except = ['auth']
					let path = document.location.href

					except.forEach(function (val) {
						if (path.toLowerCase().indexOf(val) > -1) {
							skip = true
							return false
						}
					})

					if(call || skip){
						this.is_ready = true
						setTimeout(() => {
							this.is_loading = false
						}, 500)
					}else{
						this.is_ready = false
						this.is_loading = true
					}
				}
			}
			, $route(){

				this.offSmsConfirm()

				if(this.user.uid && !this.user.sms_auth_uid && this.$route.name != 'MyPage' && this.$route.name != 'SmsConfirmRequest'){
					this.is_on_notice = true
				}
			}
			, user: {
				deep: true
				, immediate: true
				, handler: function(call){
					// console.log('watch user', call)
					if(this.user.uid && !call.sms_auth_uid && this.$route.name != 'MyPage' && this.$route.name != 'SmsConfirmRequest'){
						this.is_on_notice = true
					}else{
						if(call.uid && call.is_pin > 0){
							console.log(123, call.uid, call.is_pin)
							setTimeout( () => {
								if(!sessionStorage.getItem(process.env.VUE_APP_NAME + 'P')){
									this.$bus.$emit('onPin', { type: 'auth'})
								}
							}, 100)
						}
					}
				}
			}
		}
	}
	
</script>

<style lang="css" src="@/assets/css/reset.css"></style>
<style lang="css" src="@/assets/css/template.css"></style>
<style lang="css" src="@/assets/css/base.css"></style>

<style>

.cart-count {
	position: absolute;
	right: 10px;
	top: 5px;
	background-color: #0093e2;
	border: 1px solid #ddd;
	border-radius: 5px;
	color: white;
	font-size: 12px;
	padding: 3px 5px;
	line-height: 100%;
}


.company-list {
	font-family: "\B9D1\C740 \ACE0\B515",Malgun Gothic,Apple-Gothic,Helvetica,Dotum,\\B3CB\C6C0,Gulim
}

.cart-none-list {
	display: none;
}

.main-box-pdt { background-color: white; }
.main-box-pdt img { }

.box-shadow-top { box-shadow: 0px 3px 6px black}
.box-shadow-inset { box-shadow: 0px 0px 2px 1px #bbb inset}
.box-shadow { box-shadow: 1px 1px 3px 1px #bbb}

.border-tb { border-top: 1px solid #ddd; border-bottom: 1px solid #ddd;}
.border-lr { border-left: 1px solid #ddd; border-right: 1px solid #ddd;}
.border-right { border-right: 1px solid #ddd}

.bg-base { background-color: black}

	.logo-position {

		overflow: hidden;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 20px 0;
		background-color: var(--bg-primary);
	}
	.header-box-menu {
		border-bottom: 1px solid #ddd;
	}

	.icon-box {
		padding: 5px;
		border: 1px solid #ddd;
	}

	.bg-title {
		padding: 10px;
		box-shadow: 0 0 3px 1px gray;
	}
</style>